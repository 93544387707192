const routes = [
    {
        name: 'calendar_events.index',
        path: '/calendar-events',
        components: {
            default: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/events/Index'),
            ui_v3: () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/v3/Index'),
        },
        meta: {
            fullview: true,
            actions: true,
            breadcrumbName: 'calendar events',
            title: 'Calendar',
            size: 'large',
            flowStartsWith: 'calendar_events.index',
            allowedRoles: ['SUPER_ADMIN'],
            product: 'marketing',
        },
    },
];

export default routes;
