const routes = [
    {
        name: 'bookmarks',
        path: '/bookmarks',
        components: {
            default: () => import('@/views/homepage/bookmarks/Index'),
            ui_v3: () => import('@/views/auth/NotAuthorized'),
        },
        meta: {
            fullview: true,
        },
    },
];

export default routes;
