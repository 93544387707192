import { computed } from 'vue';
import { useStore } from 'vuex';
import { intersection } from 'lodash-es';
import { useAuthPermissions } from '@/components/auth/permissions';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners';
import IPSV2_PERMISSIONS from '@/utils/constants/integrations_partners_v2/permissions.js';
import DCS_PERMISSIONS from '@/utils/constants/data_consuming_system/permissions';

export function usePortfolioSetupMenuItem() {
    const store = useStore();

    const { hasAuthPermission, PORTFOLIO_PERMISSIONS: P_PERM } = useAuthPermissions();

    const hasAuthAccess = computed(() => store.getters['auth/hasAuthAccess']);

    const customerId = computed(() => store.getters['auth/customerId']);

    const communityId = computed(() => store.getters['auth/community']?.id);

    const profilePermissions = computed(() => store.getters['auth/profilePermissions']);

    const portfolioUser = computed(() => store.getters['auth/isPortfolioUser']);

    const hasAccessToAdminSetup = computed(() => {
        const { noContextPermissions = [], customerContextPermissions = [] } = profilePermissions.value;
        const noContextPermissionsAliases = noContextPermissions.map(({ permissionKey }) => permissionKey);

        const customerContext = customerContextPermissions.find(
            ({ customerId: contextCustomerId }) => contextCustomerId === customerId.value
        );

        const customerContextPermissionsAliases =
            customerContext?.customerContextPermissions.map(({ permissionKey }) => permissionKey) || [];

        const communityContext = customerContext?.communities.find(({ communityId }) => communityId === communityId.value);

        const communityContextPermissionsAliases =
            communityContext?.communityContextPermissions.map(({ permissionKey }) => permissionKey) || [];

        return (
            hasAuthAccess.value &&
            customerId.value &&
            intersection(
                [...noContextPermissionsAliases, ...customerContextPermissionsAliases, ...communityContextPermissionsAliases],
                [P_PERM.VIEW_CUSTOMER_DETAILS, P_PERM.VIEW_COMMUNITIES, P_PERM.VIEW_CUSTOMER_USERS]
            ).length > 0
        );
    });

    const { hasPermission, hasRolePermission } = useIntegrationPartnersStore();

    const hasSyncServiceAccess = computed(() => {
        return hasPermission(DCS_PERMISSIONS.VIEW_SCHEDULES) && !hasRolePermission;
    });

    const hasCommunityAccess = computed(() => {
        return hasPermission(IPSV2_PERMISSIONS.VIEW_COMMUNITY) && customerId.value && communityId.value && !hasRolePermission;
    });

    const hasCustomerAccess = computed(() => {
        return customerId.value && !communityId.value && hasPermission(IPSV2_PERMISSIONS.VIEW_COMMUNITY) && !hasRolePermission;
    });

    const hasPartnerAccess = computed(() => {
        return hasPermission(IPSV2_PERMISSIONS.CREATE_PARTNER) || hasPermission(IPSV2_PERMISSIONS.UPDATE_PARTNER) || hasRolePermission;
    });

    const portfolioSetupMenuItems = computed(() => ({
        items: [
            {
                icon: 'file',
                permissionChecker: hasAccessToAdminSetup.value,
                label: 'portfolio setup',
                id: '__portfolio_setup',
                children: [
                    {
                        permissionChecker: hasAuthPermission.value(P_PERM.VIEW_CUSTOMER_DETAILS),
                        label: 'company details',
                        id: 'company.index',
                        data: {
                            routeParams: {
                                customerId: customerId.value,
                            },
                        },
                    },
                    {
                        permissionChecker:
                            !portfolioUser.value && hasAuthPermission.value([P_PERM.VIEW_CUSTOMER_USERS, P_PERM.VIEW_PORTFOLIO_ADMINS]),
                        label: 'users and roles management',
                        id: 'users_mngmt',
                        data: {
                            routeParams: {
                                customerId: customerId.value,
                            },
                        },
                    },
                    {
                        permissionChecker: hasAuthPermission.value(P_PERM.VIEW_COMMUNITIES),
                        label: 'communities',
                        id: 'company.communities.index',
                        data: {
                            routeParams: {
                                customerId: customerId.value,
                            },
                        },
                    },
                    {
                        permissionChecker: hasCommunityAccess.value && !hasPartnerAccess.value,
                        label: 'integration partners',
                        id: 'ips-v2.communityDetails',
                        data: {
                            routeParams: {
                                customerId: customerId.value,
                                communityId: communityId.value,
                            },
                        },
                    },
                    {
                        permissionChecker: hasCustomerAccess.value && !hasPartnerAccess.value,
                        label: 'integration partners',
                        id: 'ips-v2.manage-customer-user-community',
                    },
                    {
                        permissionChecker: hasSyncServiceAccess.value,
                        label: 'sync service',
                        id: 'sync-service.index',
                    },
                ],
            },
        ],
    }));

    return { portfolioSetupMenuItems };
}
