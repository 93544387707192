<template>
    <Authenticator>
        <template #default="slotParams">
            <Layout>
                <component
                    :is="routerViewWrap"
                    v-if="routerReady"
                    :class="{
                        'w-full h-full overflow-auto': fullview,
                    }"
                >
                    <router-view
                        v-if="!missingCustomerContext"
                        :key="routerViewKey"
                        :name="hasV3 ? 'ui_v3' : undefined"
                    />
                </component>
                <div v-if="missingCustomerContext">
                    <div
                        class="flex items-center justify-center font-semibold font-inter p-4 py-6 bg-highlight-100 text-highlight-smallText border border-highlight-300"
                    >
                        <span><Icon
                            class="w-6 h-6 mr-2 text-highlight-textLarge"
                            name="Info"
                        /></span>
                        <span>please select a {{ requiredInfo.join(' and a ') }} to continue, then refresh the browser</span>
                    </div>
                </div>
            </Layout>
            <alert />
            <idle :logout="slotParams.logout" />
            <ws-connector />

            <permission-connector />
        </template>
        <template #unauthenticated>
            <div>
                <alert />
                <router-view />
            </div>
        </template>
    </Authenticator>
</template>

<script>
import AppsPermissionsFetcher from '@/mixins/AppsPermissionsFetcher';
import EventBus from '@/utils/EventBus';
import Icon from '@/components/ui/Icon';
import Idle from '@/components/layout/Idle';
import { mapActions, mapGetters } from 'vuex';
import Alert from '@/components/layout/v3/Alert';
import WsConnector from '@/components/wst/WSConnector';
import Authenticator from '@/components/auth/Authenticator';
import PermissionConnector from '@/components/permission_service/PermissionConnector';

import Layout from '@/components/layout/v3/Layout.vue';

import ModalViewAdapter from '@/components/layout/v3/ModalViewAdapter.vue';

import '@asmartment/qui-base/dist/qui-base.css';

export default {
    components: {
        PermissionConnector,
        WsConnector,
        Authenticator,
        Alert,
        Idle,
        Icon,
        ModalViewAdapter,
        Layout,
    },

    mixins: [AppsPermissionsFetcher],

    data: () => {
        return {
            requiredInfo: [],
            hasV3: false,
            fullview: false,
            routerReady: false,
        };
    },

    computed: {
        ...mapGetters({
            profile: 'auth/profile',
            community: 'auth/community',
        }),

        missingCustomerContext() {
            return this.requiredInfo.length;
        },

        routerViewWrap() {
            return this.fullview ? 'div' : 'modal-view-adapter';
        },

        routerViewKey() {
            return `${this.$route.name}_${this.community?.id}_${this.profile?.customerId}`;
        },
    },

    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler: function (newVal) {
                this.hasV3 = newVal.matched[0]?.components.ui_v3;

                this.fullview = newVal.matched[0]?.meta.fullview;

                this.requiredInfo = [];

                if (newVal.meta && newVal.meta.requiresCustomer && !this.profile?.customerId) this.requiredInfo.push('customer');

                if (newVal.meta && newVal.meta.requiresCommunity && !this.community) this.requiredInfo.push('community');
            },
        },
    },

    mounted() {
        this.resetActions();
        this.resetFlash();

        EventBus.on('auth-info-received', () => {});
    },

    created() {
        this.$router.isReady().then(() => (this.routerReady = true));
    },

    methods: {
        ...mapActions({
            resetActions: 'actions/resetActions',
            resetFlash: 'flashes/resetFlash',
        }),
    },
};
</script>
