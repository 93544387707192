export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_APP_PERMISSIONS = 'SET_APP_PERMISSIONS';
export const CLEAR_APP_PERMISSIONS = 'CLEAR_APP_PERMISSIONS';
export const SET_COMMUNITY = 'SET_COMMUNITY';
export const SET_COMMUNITIES = 'SET_COMMUNITIES';
export const SET_CUSTOMER_APPS = 'SET_CUSTOMER_APPS';
export const SET_CUSTOMER_COMMUNITY_APP_CODES = 'SET_CUSTOMER_COMMUNITY_APP_CODES';

export default {
    [SIGN_IN]: (state, payload) => {
        state.user = {
            ...state.user,
            ...payload,
            authenticated: true,
        };
    },
    [SIGN_OUT]: state => {
        state.user = {
            authenticated: false,
            activeProfile: state.user?.activeProfile,
            appsPermissions: {},
            profiles: undefined,
            activeCommunity: state.user?.activeCommunity,
            activeCustomerApps: [],
            activeCustomerCommunityAppCodes: [],
            username: state.user?.username, // it is cognitoUserId
        };
    },
    [SET_PROFILE]: (state, payload) => {
        state.user.activeProfile = payload;
    },
    [SET_PROFILES]: (state, payload) => {
        state.user.profiles = payload;
    },
    [SET_APP_PERMISSIONS]: (state, { appCode, permissions }) => {
        state.user.appsPermissions[appCode] = permissions;
    },
    [CLEAR_APP_PERMISSIONS]: (state, appCode) => {
        state.user.appsPermissions[appCode] = undefined;
    },
    [SET_COMMUNITY]: (state, payload) => {
        state.user.activeCommunity = payload;
    },
    [SET_COMMUNITIES]: (state, payload) => {
        state.communities = payload;
    },
    [SET_CUSTOMER_APPS]: (state, payload) => {
        state.user.activeCustomerApps = payload;
    },
    [SET_CUSTOMER_COMMUNITY_APP_CODES]: (state, payload) => {
        state.user.activeCustomerCommunityAppCodes = payload;
    },
};
