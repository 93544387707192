<template>
    <transition name="down-in">
        <AlertMessage
            v-if="alertStore.alert.message"
            :theme="alertStore.alert.type"
            :close-time="closeTime"
            @close="() => alertStore.$reset()"
        >
            <span
                v-dompurify-html="alertStore.alert.message"
                class="break-words"
            />
        </AlertMessage>
    </transition>
</template>

<script setup>
import { watch, computed } from 'vue';
import { ALERT_TYPE } from '@/utils/constants/common/alert';
import AlertMessage from '@/components/layout/alert/AlertMessage.vue';
import { useAlertStore } from '@/stores/alert';
import { useRouter } from 'vue-router';

const closeTimeout = {
    [ALERT_TYPE.SUCCESS]: 4000, // 4 seconds
    [ALERT_TYPE.ERROR]: 10000, // 10 seconds
    [ALERT_TYPE.DEFAULT]: 10000, // 10 seconds
};

const router = useRouter();
const alertStore = useAlertStore();

let timeoutId = null;

const closeTime = computed(() => closeTimeout[alertStore.alert.type] || closeTimeout[ALERT_TYPE.SUCCESS]);

watch(
    () => alertStore.alert,
    newAlert => {
        if (newAlert.message) {
            if (timeoutId) clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                alertStore.$reset();
            }, closeTime.value);
        } else if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }

        if (newAlert.back) {
            router.go(-1);
        }
    },
    { immediate: true }
);
</script>

<style scoped>
.down-in-enter-active {
    transition: all 0.35s cubic-bezier(0.71, 0.67, 0.5, 1.35);
}

.down-in-leave-active {
    transition: all 0.25s;
}

.down-in-enter-from,
.down-in-leave-to {
    opacity: 0;
    transform: translateY(-50%);
}

.down-in-enter-to,
.down-in-leave-from {
    opacity: 1;
    transform: translateY(0);
}
</style>
