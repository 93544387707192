import { defineStore } from 'pinia';
import { i18n } from '@/i18n';
import { ALERT_TYPE } from '@/utils/constants/common/alert';

export const useAlertStore = defineStore('alerts', {
    state: () => ({
        message: null,
        type: null,
        back: false,
        title: null,
    }),

    getters: {
        alert: state => ({
            message: state.message,
            type: state.type,
            back: state.back,
            title: state.title,
        }),
    },

    actions: {
        notify(type, msg, backValue = false, title = null) {
            this.message = msg;
            this.type = type;
            this.back = backValue;
            this.title = title;
        },
        notifySuccess(msg = i18n.global.t('notification.success'), backValue = false, title = null) {
            this.notify(ALERT_TYPE.SUCCESS, msg, backValue, title);
        },
        notifyError(error = i18n.global.t('notification.error'), backValue = false, title = null) {
            const errorMsg = error.message || error;
            this.notify(ALERT_TYPE.ERROR, errorMsg, backValue, title);
            console.error(error);
        },
        notifyInfo(msg = i18n.global.t('notification.info'), backValue = false, title = null) {
            this.notify(ALERT_TYPE.DEFAULT, msg, backValue, title);
        },
        notifyWarning(msg = i18n.global.t('notification.warning'), backValue = false, title = null) {
            this.notify(ALERT_TYPE.WARNING, msg, backValue, title);
        },
        notifyBrand(msg = i18n.global.t('notification.brand'), backValue = false, title = null) {
            this.notify(ALERT_TYPE.BRAND, msg, backValue, title);
        },
    },
});
