<template>
    <div class="layout">
        <header>
            <Header />
        </header>
        <nav>
            <Navigation />
        </nav>
        <section v-if="route.name && !['home', 'calendar_events.index', 'notAuthorized', 'bookmarks'].includes(route.name)">
            <Breadcrumbs
                class="items-center"
                breadcrumbs-item-classes="text-gray-600"
                breadcrumbs-link-classes="hover:text-gray-600"
            >
                <template #home-icon>
                    <QxtIcon
                        name="home-dash"
                        size="medium"
                        class="text-gray-600 block"
                    />
                </template>
            </Breadcrumbs>
            <modal-actions />
        </section>
        <main>
            <slot />
        </main>
    </div>
</template>

<script setup>
import { QxtIcon } from '@asmartment/qui-base';
import Header from '@/components/layout/v3/Header.vue';
import ModalActions from '@/components/layout/v3/PageActions.vue';
import Navigation from '@/components/layout/v3/navigation/Navigation.vue';
import Breadcrumbs from '@/components/ui/modals/Breadcrumbs.vue';
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<style scoped>
nav {
    grid-area: nav;
}

header {
    grid-area: header;
}

section {
    grid-area: breadcrumbs;
    padding: 1.5rem 1.5rem 0 1.5rem;
    position: relative;
}

main {
    grid-area: main;
    min-height: 0;
}

.layout {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    background-color: var(--gray-100);
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'nav header'
        'nav breadcrumbs'
        'nav main';
}
</style>
